$(function () {

    // const Toast = Swal.mixin({
    //     toast: true,
    //     position: 'top-end',
    //     showConfirmButton: false,
    //     timer: 3000,
    //     timerProgressBar: true,
    //     onOpen: (toast) => {
    //         toast.addEventListener('mouseenter', Swal.stopTimer);
    //         toast.addEventListener('mouseleave', Swal.resumeTimer);
    //     },
    // });

    tabInit();

    $('.count-product__counter-item--minus').on('click',function () {
        let numb = $('.count-product__counter-numb').text();
        numb = parseInt(numb);
        if(numb > 1){
            numb--;
        }
        $('.count-product__counter-numb').text(numb);
    });

    $('.count-product__counter-item--pluse').on('click',function () {
        let numb = $('.count-product__counter-numb').text();
        numb = parseInt(numb);
        numb++;
        $('.count-product__counter-numb').text(numb);
    });

    $('.site-header__cart').on('click',function (e) {
        e.stopPropagation();
        $(this).find('.site-header__cart-items').toggleClass('active')
    });

    $('.site-header__cart-items').on('click',function (e) {
        e.stopPropagation();
    })

    var galleryThumbs = new Swiper('.product-info__gallery', {
        spaceBetween: 10,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
    });
    var galleryTop = new Swiper('.product-info__image-slider', {
        slidesPerView: 1,
        thumbs: {
            swiper: galleryThumbs
        }
    });

    $('.product-info__information-share .share').on('click',function (e) {
       e.stopPropagation();
       $(this).toggleClass('active');
    });

    $('.product-content__contents-content h3').on('click',function () {
       $(this).next('[data-target]').slideToggle(300);
       $(this).toggleClass('active');
    });

    if($('.product-content__contents-content h3').length){
        $('.product-content__contents-content h3').first().addClass('active');
    }

    $('.item--more').on('click',function () {
        $('.item:not(.item--more)').slideDown(300).css({'display':'flex'});
    });

    $('.product-info__add-cart--start-order').on('click',function (e) {
        if($(window).width() < 576){
            e.preventDefault();
            e.stopPropagation();
            $('.product-info__meta-item--size').addClass('active');
        }
    });

    $('.product-info__meta-item--size .close').on('click',function () {
        $('.product-info__meta-item--size').removeClass('active');
    });

    $('.product-info__meta-item--size').on('click',function (e) {
        e.stopPropagation();
    });

    $('.number__item--plus').on('click',function () {
        let numb = $(this).siblings('.number-input').val();
        numb++;
        $(this).siblings('.number-input').val(numb);
    });

    $('.number__item--minus').on('click',function () {
        let numb = $(this).siblings('.number-input').val();
        if(numb > 0){
            numb--;
        }
        $(this).siblings('.number-input').val(numb);
    });

    $('.mega-menu-cat__cat-item').on('click',function (e) {
       e.preventDefault();
    });

    $('.number__item').on('click',function () {
        let count = 0;
        let price_total = 0;
        $('.number-input').each(function (index,el) {
          let one_count = parseInt($(el).val());
          let parent = $(el).closest('.variations__tabc-color');
          let price_el = parent.find('.price__numb');
          let price = parseInt(price_el.attr('data-variations-price'));
          let price_total_one = price * one_count;
          price_total += price_total_one;
          count += one_count;
          $('.variations-count__pieces-count .count').text(count);
          $('.variations-count__pieces-price .price').text(price_total);
          let shipping = parseInt($('.variations-count__shipping-price').attr('data-price'));
          $('.variations-count__total-price').text(price_total + shipping);
        })
    });

    $('.number-input').focusout(function () {
        let count = 0;
        let price_total = 0;
        $('.number-input').each(function (index,el) {
            let one_count = parseInt($(el).val());
            let parent = $(el).closest('.variations__tabc-color');
            let price_el = parent.find('.price__numb');
            let price = parseInt(price_el.attr('data-variations-price'));
            let price_total_one = price * one_count;
            price_total += price_total_one;
            count += one_count;
            $('.variations-count__pieces-count .count').text(count);
            $('.variations-count__pieces-price .price').text(price_total);
            let shipping = parseInt($('.variations-count__shipping-price').attr('data-price'));
            $('.variations-count__total-price').text(price_total + shipping);
        })
    });

    if($('.number-input').length){
        $('.number-input').each(function (index,el) {
            if($(el).val() === ''){
                $(el).val(0);
            }
        });
    }

    $('.variations__tab-size').on('click',function () {
        setTimeout(function () {
            $('.number-input').each(function (index,el) {
                if($(el).val() === ''){
                    $(el).val(0);
                }
            });
        },10)
    });

    $('.cart-items__item-add').on('click',function () {
       let el = $(this).parent('.cart-items__item-info-left').find('[data-count]');
       let count = el.attr('data-count');
       count++;
       el.attr('data-count',count);
       el.text(count);
    });

    $('.cart-items__item-minus').on('click',function () {
        let el = $(this).parent('.cart-items__item-info-left').find('[data-count]');
        let count = el.attr('data-count');
        if(count > 1){
            count--;
        }
        el.attr('data-count',count);
        el.text(count);
    });

    /*$('.mega-menu-cat .container > ul > li').hover(function () {
        $(this).addClass('active');
        $(this).siblings().removeClass('active');
    });*/

    $('.mega-menu-cat').on('click',function (e) {
        e.stopPropagation()
    });

    $('.mega-menu-cat .container > ul li').on('click',function () {
       var tab = $(this).attr('data-tab');
       $('.mega-menu-cat__cat-wrapper').each(function (index,el) {
           let tabc = $(el).attr('data-tabc');
           if(tab === tabc){
               $(el).addClass('active');
               $(el).siblings('.mega-menu-cat__cat-wrapper').removeClass('active');
           }
       })
    });

    $('.has-childe').on('click',function (e) {
       $(this).find('> ul').slideToggle(300);
       $(this).toggleClass('active');
       e.stopPropagation();
    });

    $('body').on('click',function () {
        $('.mega-menu-cat').removeClass('active');
        $('.site-header__left-item--cat').removeClass('active');
        $('.mega-menu-cat .container > ul > li').first().removeClass('active');
        $('.product-info__meta-item--size').removeClass('active');
        $('.product-info__information-share .share').removeClass('active');
        $('.site-header__cart-items').removeClass('active');
    });

    $('.site-header__left-item--cat').on('click',function (e) {
        $('.mega-menu-cat').toggleClass('active');
        $(this).toggleClass('active');
        e.stopPropagation();
        if($(window).width() < 576){
            $('.mega-menu-site-responsive').toggleClass('active');
        }/*else{
            $('.mega-menu-cat .container > ul > li').first().toggleClass('active');
        }*/
    });


    new Swiper('.momentary-products', {
        autoplay: {
            delay: 2000,
        },
        loop: true,
        breakpoints: {
            1200: {
                slidesPerView: 3.63,
                spaceBetween: 20,
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 2.1,
                spaceBetween: 10,
            },
            576: {
                slidesPerView: 1.43,
                spaceBetween: 10,
            },
            400: {
                slidesPerView: 1.5,
                spaceBetween: 10,
            },
            360: {
                slidesPerView: 1.2,
                spaceBetween: 10,
            }
        }
    });

    // $('a').click(function (event) {
    //     let scrollOffset = 0;
    //
    //     if ($(this).is('a[href^="#"]') && $(this).attr('href').length >= 2) {
    //         $('html, body').animate({
    //             scrollTop: $($.attr(this, 'href')).offset().top + scrollOffset
    //         }, 1000);
    //         return false;
    //     }
    // });

    new Swiper('.popular-cat-swiper', {
        slidesPerView: 8.4,
        spaceBetween: 10,
        breakpoints: {
            1600: {
                slidesPerView: 10.5,
                spaceBetween: 10,
            },
            1200: {
                slidesPerView: 8.4,
                spaceBetween: 10
            },
            992: {
                slidesPerView: 6.4,
                spaceBetween: 10
            },
            768: {
                slidesPerView: 5.4,
                spaceBetween: 10
            },
            576: {
                slidesPerView: 4.4,
                spaceBetween: 10
            },
            450: {
                slidesPerView: 3.4,
                spaceBetween: 10
            },
            350: {
                slidesPerView: 2.6,
                spaceBetween: 10
            },
            300: {
                slidesPerView: 1.8,
                spaceBetween: 10
            }
        }
    });


    $('.product__content').each(function (index, el) {
        $(el).addClass('product__content' + index);
        $(el).parent().find('.product__nav--next').addClass('product__nav--next' + index);
        $(el).parent().find('.product__nav--prev').addClass('product__nav--prev' + index);
        new Swiper('.product__content' + index, {
            slidesPerView: 4,
            spaceBetween: 22,
            navigation: {
                nextEl: '.product__nav--next' + index,
                prevEl: '.product__nav--prev' + index,
            },
            breakpoints: {
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 22,
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 22,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 22,
                },
                576: {
                    slidesPerView: 2,
                    spaceBetween: 22,
                },
                300: {
                    slidesPerView: 1.1,
                    spaceBetween: 22,
                }
            }
        });
    });

    new Swiper('.top-sellers__content-swiper', {
        slidesPerView: 6,
        spaceBetween: 30,
        allowTouchMove: false,
        navigation: {
            nextEl: '.top-sellers__nav--next',
            prevEl: '.top-sellers__nav--prev',
        },
        breakpoints: {
            1200: {
                allowTouchMove: true,
                slidesPerView: 6,
            },
            992: {
                allowTouchMove: true,
                slidesPerView: 4.95,
            },
            768: {
                allowTouchMove: true,
                slidesPerView: 3.75,
            },
            576: {
                allowTouchMove: true,
                slidesPerView: 2.85,
            },
            450: {
                allowTouchMove: true,
                slidesPerView: 2.1,
            },
            440:{
                allowTouchMove: true,
                slidesPerView: 2.27,
            },
            430:{
                allowTouchMove: true,
                slidesPerView: 2.19,
            },
            420:{
                allowTouchMove: true,
                slidesPerView: 2.11,
            },
            410:{
                allowTouchMove: true,
                slidesPerView: 2.1,
            },
            400:{
                allowTouchMove: true,
                slidesPerView: 2.05,
            },
            390:{
                allowTouchMove: true,
                slidesPerView: 2,
            },
            380:{
                allowTouchMove: true,
                slidesPerView: 1.95,
            },
            370: {
                allowTouchMove: true,
                slidesPerView: 1.85,
            },
            360:{
                allowTouchMove: true,
                slidesPerView: 1.85,
            },
            350:{
                allowTouchMove: true,
                slidesPerView: 1.8,
            },
            340:{
                allowTouchMove: true,
                slidesPerView: 1.75,
            },
            330:{
                allowTouchMove: true,
                slidesPerView: 1.7,
            },
            320:{
                allowTouchMove: true,
                slidesPerView: 1.65,
            },
            300: {
                allowTouchMove: true,
                slidesPerView: 1.59,
            }
        }
    });

    $('.product-info__meta-color .item:not(.item--more)').on('click', function () {
        if (!$(this).hasClass('active')) {
            $(this).addClass('active');
            $(this).siblings().removeClass('active');
        }
    });

    $('.filter__brand-head,.filter__color-head').on('click', function () {
        $(this).siblings().slideToggle(200);
        $(this).toggleClass('active');
    });

    $('.product-content__nav-item').on('click', function () {
        setTimeout(function () {
            $('.product__content--other').each(function (index, el) {
                $(el).addClass('product__content--other' + index);
                $(el).find('.next').addClass('next' + index);
                $(el).find('.prev').addClass('prev' + index);
                new Swiper('.product__content--other' + index, {
                    slidesPerView: 4.285,
                    spaceBetween: 20,
                    navigation: {
                        nextEl: '.product--other .next' + index,
                        prevEl: '.product--other .prev' + index,
                    },
                    breakpoints: {
                        1600: {
                            slidesPerView: 4.69
                        },
                        1200: {
                            slidesPerView: 4.285,
                        },
                        992: {
                            slidesPerView: 3.6,
                        },
                        768: {
                            slidesPerView: 2.9,
                        },
                        576: {
                            slidesPerView: 3.3,
                        },
                        400: {
                            slidesPerView: 2.8,
                        },
                        300: {
                            slidesPerView: 2.5
                        }
                    }
                });
            })
        }, 10);
    });

    $('.product-info__meta-item select').selectize();

    $('.filters-summary__sorting-responsive select').selectize();

    if($('.login__code-second').length){
        var countdownNumberEl = document.getElementById('countdown-number');
        var countdown = 60;

        countdownNumberEl.textContent = countdown;

        time_count = setInterval(function () {
            if (countdown > 0) {
                countdown = --countdown <= 0 ? 60 : countdown;

                countdownNumberEl.textContent = countdown;
            } else {
                clearTime();
            }
        }, 1000);

        function clearTime() {
            clearInterval(time_count);
        }
    }


    if ($('.progress-ring').length) {
        $('.progress-ring').each(function (index, el) {

                let w = $(el).width();
                let stroke_width = parseInt($(el).find('.progress-ring__circle').css('stroke-width'));
                let r = (w / 2) - (stroke_width * 2);
                $(el).find('.progress-ring__circle').attr('r', r);
                let m = (r * 2 * Math.PI);
                let ml = m / 10;
                $(el).find('.progress-ring__circle').attr('stroke-dasharray', `${m} ${m}`);
                $(el).find('.progress-ring__circle').attr('stroke-dashoffset', m);
                let rate = $(el).attr('data-rate');
                rate = m - (ml * rate);
                $(el).find('.progress-ring__circle').attr('stroke-dashoffset', rate);

        })
        // let w = $('.progress-ring').width();
    }

    if ($('.comments__list-item').length) {
        $('.comments__list-item').each(function (index, el) {
            if ($(el).has('.children').length > 0) {
                $(el).addClass('has-childe');
            }
        })
    }

    if ($('.filter__category-item').length) {
        var el = $('.filter__category-item');
        let size = el.height();
        let marginBottom = parseInt(el.css('margin-bottom'));
        size = size + marginBottom;
        $('.filter__category-items').each(function (index, el) {
            if ($(el).find('.filter__category-item').length < 6) {
                $(el).siblings('.filter__category-more').hide();
            } else {
                $(el).css({'height': size * 6});
            }
        });
        $('.filter__category-more').on('click', function () {
            let el_prev = $(this).prev('.filter__category-items');
            let len = el_prev.find('.filter__category-item').length;
            if (!$(this).hasClass('active')) {
                el_prev.css({'height': size * len});
                $(this).find('span').text('مشاهده کمتر');
            } else {
                el_prev.css({'height': size * 6});
                $(this).find('span').text('مشاهده بیشتر');
            }
            $(this).toggleClass('active');
        });
    }

    if ($('.filter__brand-content').length) {
        $('.filter__brand-content').mCustomScrollbar();
    }
    if ($('.filter__color-items').length) {
        $('.filter__color-items').mCustomScrollbar();
    }

    new Swiper('.cat-one__slider', {
        slidesPerView: 1,
        spaceBetween: 30
    });

    new Swiper('.cat-one-products__swiper', {
        slidesPerView: 3.87,
        spaceBetween: 25,
        breakpoints:{
            1200:{
                slidesPerView: 3.87,
                spaceBetween: 25,
            },
            992:{
                slidesPerView: 2.87,
                spaceBetween: 25,
            },
            768:{
                slidesPerView: 2.4,
                spaceBetween: 25,
            },
            576:{
                slidesPerView: 2.4,
                spaceBetween: 25,
            },
            450:{
                slidesPerView: 1.7,
                spaceBetween: 25,
            },
            300:{
                slidesPerView: 1.3,
                spaceBetween: 25,
            }
        }
    });

    new Swiper('.top-sellers__content-swiper--cat-one', {
        slidesPerView: 4.5,
        spaceBetween: 30,
        breakpoints:{
            1200:{
                slidesPerView: 4.5,
                spaceBetween: 30,
            },
            992:{
                slidesPerView: 3.7,
                spaceBetween: 30,
            },
            768:{
                slidesPerView: 2.65,
                spaceBetween: 30,
            },
            576:{
                slidesPerView: 2.85,
                spaceBetween: 30,
            },
            450:{
                slidesPerView: 1.95,
                spaceBetween: 30,
            },
            430:{
                slidesPerView: 2.09,
                spaceBetween: 30,
            },
            420:{
                slidesPerView: 2,
                spaceBetween: 30,
            },
            410:{
                slidesPerView: 1.94,
                spaceBetween: 30,
            },
            400:{
                slidesPerView: 1.9,
                spaceBetween: 30,
            },
            390:{
                slidesPerView: 1.84,
                spaceBetween: 30,
            },
            380:{
                slidesPerView: 1.8,
                spaceBetween: 30,
            },
            370:{
                slidesPerView: 1.74,
                spaceBetween: 30,
            },
            360:{
                slidesPerView: 1.7,
                spaceBetween: 30,
            },
            350:{
                slidesPerView: 1.64,
                spaceBetween: 30,
            },
            340:{
                slidesPerView: 1.6,
                spaceBetween: 30,
            },
            330:{
                slidesPerView: 1.54,
                spaceBetween: 30,
            },
            320:{
                slidesPerView: 1.5,
                spaceBetween: 30,
            },
            310:{
                slidesPerView: 1.46,
                spaceBetween: 30,
            },
            300:{
                slidesPerView: 1.4,
                spaceBetween: 30,
            }
        }
    });

    new Swiper('.cart-method__items', {
        slidesPerView: 4,
        spaceBetween: 30,
        allowTouchMove: false,
        navigation: {
            nextEl: '.cart-method__nav--next',
            prevEl: '.cart-method__nav--prev',
        },
        breakpoints: {
            1200: {
                slidesPerView: 4,
                spaceBetween: 30,
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 30,
                allowTouchMove: true,
            },
            576:{
                slidesPerView: 2,
                spaceBetween: 30,
                allowTouchMove: true,
            },
            300:{
                slidesPerView: 1,
                spaceBetween: 30,
                allowTouchMove: true,
            }
        }
    });
    /*size_li = $(".filter__category-items .filter__category-item").size();
    x=6;
    $('.filter__category-items .filter__category-item:lt('+x+')').show();
    $('.filter__category-more').click(function () {
        x= (x+5 <= size_li) ? x+5 : size_li;
        $('.filter__category-items .filter__category-item:lt('+x+')').show();
        // $('#showLess').show();
        // if(x == size_li){
        //     $('#loadMore').hide();
        // }
    });

    // $('#showLess').click(function () {
    //     x=(x-5<0) ? 3 : x-5;
    //     $('#myList li').not(':lt('+x+')').hide();
    //     $('#loadMore').show();
    //     $('#showLess').show();
    //     if(x == 3){
    //         $('#showLess').hide();
    //     }
    // });*/

    $('.response-head__menu').on('click', function (e) {
        e.stopPropagation();
        $('.mega-menu-site-responsive').toggleClass('active');
        $('.mask-shadow').toggleClass('active');
        $('body').toggleClass('overflow-none');
    });

    $('.mega-menu-site-responsive').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).parent().toggleClass('active');
        $(this).siblings('ul').slideToggle(500);
    });

    $('.mega-menu-site-responsive__header').on('click', function (e) {
        e.stopPropagation();
    });

    $('.mega-menu-site-responsive__search').on('click', function (e) {
        e.stopPropagation();
    });
    $('#res-menu-body').on('click', function (e) {
        e.stopPropagation();
    });

    $('.mega-menu-site-responsive > .mega-menu-site-responsive__body ul > .menu-item-has-children > a').on('click', function (e) {
        e.preventDefault();
        // $( this ).parent().toggleClass( 'active' );
    });

    $('.mega-menu-site-responsive__header .close').on('click', function () {
        $('.mega-menu-site-responsive').toggleClass('active');
        $('.mask-shadow').toggleClass('active');
        $('body').toggleClass('overflow-none');
    });

    $('.mask-shadow').on('click', function () {
        $('.mega-menu-site-responsive').toggleClass('active');
        $(this).toggleClass('active');
    });

    $('.mega-menu-site-responsive > .mega-menu-site-responsive__body ul > .menu-item-has-children > ul').slideToggle(0);
    // $( '.mega-menu-site-responsive > .mega-menu-site-responsive__body ul > .menu-item-has-children > ul > li > ul' ).slideToggle( 0 );
    $('.mega-menu-site-responsive > .mega-menu-site-responsive__body ul > .menu-item-has-children > a').on('click', function () {
        $(this).siblings('ul').slideToggle(500);
        $(this).parent().toggleClass('active');
    });

    $('.mega-menu-site-responsive > ul > .menu-item-has-children > ul > li > a').on('click', function () {
        $(this).siblings('ul').slideToggle(500);
        $(this).parent().toggleClass('active');
    });

    $('.mega-menu-site-responsive__search form input').focusin(function () {
        $(this).siblings('label').addClass('active');
    });

    $('.mega-menu-site-responsive__search form input').focusout(function () {
        if ($(this).val() === '') {
            $(this).siblings('label').removeClass('active')
        }
    });

    $('.cat-filter-responsive').on('click', function () {
        $('.cat-filter-sidebar').toggleClass('active');
    });
    $('.cat-filter-sidebar .close').on('click', function () {
        $('.cat-filter-sidebar').removeClass('active');
    });

    var time;
    if($('.not-found').length){
        var el = $('.not-found').find('.count-down');
        var numb = $('.not-found').find('.count-down').attr('data-time');
        el.text(numb);
        numb = parseInt(numb);
        time = setInterval(function () {
            if(numb > 0){
                numb--;
            }else {
                clearTime();
                /* here code redirect */
            }
            el.text(numb);
        },1000);
        function clearTime() {
            clearInterval(time);
        }
    }
    AOS.init();
});

/**
 * ticker typing
 * @param {int} [typeSpeed] - Speed of typing.
 * @param {int} [delay] - delay in typing.
 */
$.fn.tickerTyping = function () {
    var typeSpeed = 30,
        delay = 2000,
        $this = $(this),
        $children = $this.children(),
        texts = $children.map(function () {
            return $(this).text();
        }).get();
    var i = 0,
        s = 0;
    var step = function () {
        var $current = $children.eq(i),
            $a = $current.find('a:first');
        $children.hide();
        $current.show();
        $a.text(texts[i].substr(0, s));
        if (s == texts[i].length) {
            s = 0;
            if (i == $children.length - 1) {
                i = 0;
            } else {
                i++;
            }
            setTimeout(step, delay);
        } else {
            setTimeout(step, typeSpeed);
        }
        s++;
    };
    step();
};

/**
 * generate tab
 * @param {string} [tabIndex] - The tabIndex is value of data-tabindex in parent tab.
 */
function tabInit(tabIndex) {
    if (typeof tabIndex === "undefined" || tabIndex === null) {
        $('[data-tabindex]').each(function () {
            let ele = $(this),
                dataTabindex = ele.attr('data-tabindex'),
                hasTrueTab = false;
            ele.find(' .tab-content [data-tabc][data-parent="' + dataTabindex + '"]').hide();
            ele.find(' .tab-title  [data-tab][data-parent="' + dataTabindex + '"]').each(function () {
                let i;
                if ($(this).hasClass('active')) {
                    i = $(this).attr('data-tab');
                    ele.find('.tab-content [data-tabc="' + i + '"][data-parent="' + dataTabindex + '"]').addClass('active').show();
                    hasTrueTab = true;
                }
                if (hasTrueTab !== true) {
                    ele.find('.tab-content [data-tabc="' + i + '"][data-parent="' + dataTabindex + '"]:first-of-type').show();
                    ele.find('.tab-title [data-tab="' + i + '"][data-parent="' + dataTabindex + '"]:first-of-type').addClass('active');
                }
            });
            ele.find(' .tab-title [data-tab][data-parent="' + dataTabindex + '"]').click(function () {
                if (!$(this).hasClass('active')) {
                    let t = $(this).attr('data-tab'),
                        oldActiveTab = ele.find(' .tab-title  [data-tab][data-parent="' + dataTabindex + '"].active').attr('data-tab');
                    ele.find(' .tab-title  [data-tab=' + oldActiveTab + '][data-parent="' + dataTabindex + '"]').removeClass('active');
                    ele.find(' .tab-content  [data-tabc=' + oldActiveTab + '][data-parent="' + dataTabindex + '"]').removeClass('active').hide();
                    $(this).addClass('active');
                    ele.find(' .tab-content  [data-tabc="' + t + '"][data-parent="' + dataTabindex + '"]').show();
                }
            });
        });
    } else {
        let dataTabindex = tabIndex;
        tabIndex = '[data-tabindex=' + tabIndex + ']';
        $(tabIndex).find(' .tab-content [data-tabc][data-parent="' + dataTabindex + '"]').hide();
        $(tabIndex).find(' .tab-title [data-tab][data-parent="' + dataTabindex + '"]').each(function () {
            var i;
            if ($(this).hasClass('active')) {
                i = $(this).attr('data-tab');
                $(tabIndex).find(' .tab-content  [data-tabc="' + i + '"][data-parent="' + dataTabindex + '"]').addClass('active').show();
            }
        });
        $(tabIndex).find(' .tab-title  [data-tab][data-parent="' + dataTabindex + '"]').click(function () {
            if (!$(this).hasClass('active')) {
                let t = $(this).attr('data-tab'),
                    oldActiveTab = $(tabIndex).find(' .tab-title  [data-tab][data-parent="' + dataTabindex + '"].active').attr('data-tab');
                $(tabIndex).find(' .tab-title  [data-tab=' + oldActiveTab + '][data-parent="' + dataTabindex + '"]').removeClass('active');
                $(tabIndex).find(' .tab-content  [data-tabc=' + oldActiveTab + '][data-parent="' + dataTabindex + '"]').removeClass('active').hide();
                $(this).addClass('active');
                $(tabIndex).find(' .tab-content  [data-tabc="' + t + '"][data-parent="' + dataTabindex + '"]').show();
            }
        });
    }
    $('body').on('click', '[data-tabindex-current]', function () {
        let dataTabindex = $(this).attr('data-tabindex-current'),
            ele = $('[data-tabindex="' + dataTabindex + '"]'),
            tabCurrent = $(this).attr('data-tab-current');
        ele.find('.tab-title  [data-tab][data-parent="' + dataTabindex + '"]').removeClass('active');
        ele.find('.tab-content  [data-tabc][data-parent="' + dataTabindex + '"]').hide();
        ele.find('.tab-title  [data-tab="' + tabCurrent + '"][data-parent="' + dataTabindex + '"]').addClass('active');
        ele.find('.tab-content  [data-tabc="' + tabCurrent + '"][data-parent="' + dataTabindex + '"]').fadeIn();
    });
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 *  block loader
 */
function blockLoader(status, element, title) {
    let loaderDom;
    if (typeof status === "undefined" || status === null) {
        status = 'show';
    }
    if (typeof element === "undefined" || element === null) {
        element = 'body';
    }
    if (typeof title === "undefined" || title === null) {
        title = 'در حال بارگذاری ...';
    }
    loaderDom = '<div class="loader-overly"><div class="loader"><div class="spinner"><div></div><div></div><div></div><div></div></div>' + title + '</div>';
    let elementName = element;
    if (typeof element == 'string') {
        element = $(element);
    }
    if (status == 'show') {
        element.append(loaderDom);
        element.find('.loader-overly').show();
        if (elementName == 'body') {
            element.addClass('loader-locked');
            element.find('.loader-overly').addClass('loader-overly_fixed');
        }
    } else {
        element.find('.loader-overly').hide();
        element.find('.loader-overly').remove();
        if (elementName == 'body') {
            element.removeClass('loader-locked');
        }
    }
}

$.fn.extend({
    donetyping: function (callback, timeout) {
        timeout = timeout || 1e3; // 1 second default timeout
        var timeoutReference,
            doneTyping = function (el) {
                if (!timeoutReference) return;
                timeoutReference = null;
                callback.call(el);
            };
        return this.each(function (i, el) {
            var $el = $(el);
            // Chrome Fix (Use keyup over keypress to detect backspace)
            // thank you @palerdot
            $el.is(':input') && $el.on('keyup keypress paste', function (e) {
                // This catches the backspace button in chrome, but also prevents
                // the event from triggering too preemptively. Without this line,
                // using tab/shift+tab will make the focused element fire the callback.
                if (e.type == 'keyup' && e.keyCode != 8) return;

                // Check if timeout has been set. If it has, "reset" the clock and
                // start over again.
                if (timeoutReference) clearTimeout(timeoutReference);
                timeoutReference = setTimeout(function () {
                    // if we made it here, our timeout has elapsed. Fire the
                    // callback
                    doneTyping(el);
                }, timeout);
            }).on('blur', function () {
                // If we can, fire the event since we're leaving the field
                doneTyping(el);
            });
        });
    }
});

/* serialize object */
$.fn.serializeObject = function () {
    "use strict";
    var a = {}, b = function (b, c) {
        var d = a[c.name];
        "undefined" != typeof d && d !== null ? $.isArray(d) ? d.push(c.value) : a[c.name] = [d, c.value] : a[c.name] = c.value
    };
    return $.each(this.serializeArray(), b), a
};
/*
Replace all SVG images with inline SVG */
jQuery('img.svg').each(function () {
    var $img = jQuery(this);
    var imgID = $img.attr('id');
    var imgClass = $img.attr('class');
    var imgURL = $img.attr('src');

    jQuery.get(imgURL, function (data) {
        // Get the SVG tag, ignore the rest
        var $svg = jQuery(data).find('svg');

        // Add replaced image's ID to the new SVG
        if (typeof imgID !== 'undefined') {
            $svg = $svg.attr('id', imgID);
        }
        // Add replaced image's classes to the new SVG
        if (typeof imgClass !== 'undefined') {
            $svg = $svg.attr('class', imgClass + ' replaced-svg');
        }

        // Remove any invalid XML tags as per http://validator.w3.org
        $svg = $svg.removeAttr('xmlns:a');

        // Replace image with new SVG
        $img.replaceWith($svg);

    }, 'xml');

});

// map
function google_maps() {
    let divs = document.querySelectorAll(".google-map");
    let i = 0;
    if (divs.length) {
        Object.entries(divs).map((object) => {
            i++;
            let lat = parseFloat(object[1].getAttribute('data-lat'));
            let long = parseFloat(object[1].getAttribute('data-long'));
            let myLatLng = {lat: lat, lng: long};
            let data_id = 'googlemap' + i;
            object[1].setAttribute("id", data_id);
            let mapProp = {
                center: new google.maps.LatLng(lat, long),
                zoom: 18,
            };

            let map = new google.maps.Map(document.getElementById(data_id), mapProp);
            let marker = new google.maps.Marker({
                position: myLatLng,
                map: map,
                draggable: true,
                animation: google.maps.Animation.DROP,
            });

            function toggleBounce() {
                if (marker.getAnimation() !== null) {
                    marker.setAnimation(null);
                } else {
                    marker.setAnimation(google.maps.Animation.BOUNCE);
                }
            }

        });
    }
}

// say-hello
if (window.console) {
    console.log('%c👋 ivahid Says Hello %civahid is waiting for programmer like you, send resume: %cinfo@ivahid.com', 'color: #ff4342; font-size: 2rem; font-family: Arial; font-weight: bolder', 'color: #454851; font-size: 1.1rem; font-family: Arial;', 'color: #1a9df4; font-size: 1.1rem; font-family: Arial;');
}
